import React from "react";
import LayoutMain from "../layouts/LayoutMain";
import Seo from "../components/seo";
import { Row, Col, Typography, Divider } from "antd";
import ContactForm from "../components/forms/ContactForm.js";
import InTheWild from "../components/sections/InTheWild";
const { Title, Paragraph } = Typography;

const AboutPage = () => (
  <LayoutMain>
    <Seo
      title="About"
      description="inFlow helps professional organisations create interactive decision-making tools such as flow charts, decision trees or algorithms. Improve your process and increase engagement with our world-class tools."
    />
    <Row
      justify="center"
      className="heading"
      style={{ backgroundColor: "#fff" }}
    >
      <Col xs={24} sm={20} md={20} lg={16} xl={12}>
        <Title>About</Title>
        <Title level={2} style={{ color: "white" }}>
          Our story
        </Title>
      </Col>
    </Row>
    <Row justify="center" className="section section-text">
      <Col xs={24} sm={20} md={20} lg={16} xl={12}>
        <Paragraph>
          Our story starts in the 1990's when our publishing house started to
          create decision trees for the medical sector. Mostly in print, but
          also with psychical installations for use in clinics, presentations,
          et cetera.
        </Paragraph>
        <Title level={3}>Interactive flowcharts</Title>
        <Paragraph>
          For the last few years we have been using different types of
          interactive flowcharts within our products in the medical sector.
          Professional users appreciate these charts a lot.
        </Paragraph>
        <Paragraph>
          In 2019 we started to think about ways to expand the use of our
          charts. We wanted to create a service that serves interactive charts
          to external products. This way we are able to help more organisations
          create new experiences and explore new opportunities.
        </Paragraph>
        <Title level={3}>2020</Title>
        <Paragraph>
          During the pandemic we (re)wrote all code and condensed our expertise
          into this product: inFlow. A service which allows for easy creation
          and integration while setting a high standard for interaction design
          and usability.
        </Paragraph>
        <Paragraph>
          We want to turn often boring and static documents, diagrams and tasks
          into beautiful experiences.
        </Paragraph>
        <Title level={3}>A great start</Title>
        <Paragraph>
          Currently, our first users are implementing custom charts within their
          digital products through smart embeds. A few of them are even sharing
          data!
        </Paragraph>
        <Paragraph>
          We're looking forward to work with more organisations to combine
          expertise and create new experiences.
        </Paragraph>
        <Divider dashed />
        <Title level={3}>Questions?</Title>
        <Paragraph>
          For questions, remarks and other inquiries please contact Robbert van
          Zuiden through the form below. You can use the 'Get a quote' chart if
          you have a set project in mind. We're happy to talk!
        </Paragraph>
      </Col>
    </Row>
    <Divider dashed type={{ margin: "0" }} />
    <InTheWild />
    <Divider dashed type={{ margin: "0" }} />
    <Row
      justify="center"
      className="section"
      style={{ backgroundColor: "#f1f3f5" }}
    >
      <Col xs={24} sm={12} md={10} lg={8} xl={6}>
        <ContactForm message="Thanks for your interest in our product. Get in touch below!" />
      </Col>
    </Row>
  </LayoutMain>
);

export default AboutPage;
