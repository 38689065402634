import React from "react";
import { Button, Col, Row, Typography } from "antd";
import { FlagIcon } from "react-flag-kit";
const { Paragraph, Title } = Typography;

const InTheWild = () => {
  return (
    <>
      <Row
        justify="center"
        className="section"
        style={{ backgroundColor: "#fff", padding: "96px 0" }}
      >
        <Col xs={24} sm={20} md={20} lg={18} xl={20}>
          <Row justify="center">
            <Col style={{ padding: "0 24px" }}>
              <Title
                level={2}
                className="typography-nice"
                style={{ textAlign: "center" }}
              >
                Our charts in the wild
              </Title>
              <Paragraph
                style={{
                  fontSize: "125%",
                  textAlign: "center",
                }}
              >
                These organisations use inFlow charts in their products
              </Paragraph>
            </Col>
          </Row>
          <Row justify="center" align="middle" style={{ marginTop: "24px" }}>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={6}
              style={{ padding: "0 12px" }}
            >
              <div
                style={{
                  backgroundColor: "#ea690a",
                  padding: "24px",
                  borderRadius: "6px",
                  boxShadow: "0 -2px 6px rgba(0, 0, 0, 0.2)",
                  marginBottom: "24px",
                }}
              >
                <Title style={{ color: "#fff", fontSize: "18px" }}>
                  Acute Psychiatrie apps <FlagIcon code="NL" size={16} />
                </Title>
                <Paragraph
                  style={{ fontSize: "85%", marginTop: "16px", color: "#fff" }}
                >
                  'Acute Psychiatrie' contains information about acute
                  psychiatric disorders and is backed by the Dutch Association
                  for Psychiatry.
                </Paragraph>
                <Paragraph
                  style={{ fontSize: "85%", marginTop: "16px", color: "#fff" }}
                >
                  The mobile apps (available on iOS & Android) contain multiple
                  interactive flowcharts.
                </Paragraph>
                <a
                  href="https://itunes.apple.com/nl/app/acute-psychiatrie/id1364451803?mt=8"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <img
                    src="https://linkmaker.itunes.apple.com/assets/shared/badges/en-us/appstore-lrg.svg"
                    alt="Acute Psychiatrie op Apple AppStore"
                    style={{
                      width: "100px",
                      position: "relative",
                      top: "-7px",
                    }}
                  />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.acutepsychiatrieapp&amp;pcampaignid=acute-psychiatrie-web"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <img
                    src="https://play.google.com/intl/en_us/badges/images/generic/nl_badge_web_generic.png"
                    alt="Acute Psychiatrie op Google Play"
                    style={{ width: "115px" }}
                  />
                </a>
              </div>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={6}
              style={{ padding: "0 12px" }}
            >
              <div
                style={{
                  backgroundColor: "#09c",
                  padding: "24px",
                  borderRadius: "6px",
                  boxShadow: "0 -2px 6px rgba(0, 0, 0, 0.2)",
                  marginBottom: "24px",
                }}
              >
                <Title style={{ color: "#fff", fontSize: "18px" }}>
                  LTA Antistollingszorg <FlagIcon code="NL" size={16} />
                </Title>
                <Paragraph
                  style={{ fontSize: "85%", marginTop: "16px", color: "#fff" }}
                >
                  The 'Landelijke Transmurale Afspraak (LTA) Antistollingszorg'
                  is a medical guideline which provides guidance for the proper
                  use of noval Oral Anticoagulants.
                </Paragraph>
                <Paragraph style={{ fontSize: "85%", color: "#fff" }}>
                  This app contains 16 interactive flowcharts and uses two
                  interactions for each chart. Interactions are pre-selected
                  based on the device of the user.
                </Paragraph>
                <Paragraph style={{ fontSize: "85%", color: "#fff" }}>
                  Check out the charts under 'Periprocedureel Beleid' or
                  'Bloedingen onder antitrombotica'.
                </Paragraph>
                <Button style={{ marginTop: "8px" }}>
                  <a
                    href="https://lta-antistollingszorg.nl/periprocedureel-beleid-doac/02-periprocedureel-beleid-doac-apixaban"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    View the app
                  </a>
                </Button>
              </div>
            </Col>

            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={6}
              style={{ padding: "0 12px" }}
            >
              <div
                style={{
                  backgroundColor: "#1d396e",
                  padding: "24px",
                  borderRadius: "6px",
                  boxShadow: "0 -2px 6px rgba(0, 0, 0, 0.2)",
                  marginBottom: "24px",
                }}
              >
                <Title style={{ color: "#c9d22a", fontSize: "18px" }}>
                  Kenniscentrum Antistolling Transmuraal Noord-Holland{" "}
                  <FlagIcon code="NL" size={16} />
                </Title>
                <Paragraph
                  style={{
                    fontSize: "85%",
                    marginTop: "16px",
                    color: "#c9d22a",
                  }}
                >
                  The 'Kenniscentrum Antistolling Transmuraal Noord-Holland' is
                  a regional knowledge centre for Oral Anticoagulants which uses
                  a multitude of Premium inFlow components on their to-be
                  released website.
                </Paragraph>
                <Paragraph
                  style={{
                    fontSize: "85%",
                    marginTop: "16px",
                    color: "#c9d22a",
                  }}
                >
                  Partly based on the guidelines from the 'LTA
                  Antistollingszorg' (above), this website delivers a
                  well-designed interactive experience by combining existing
                  guidelines with their own content. In this way, the to-be
                  released website is a great knowledge base for everyone
                  involved in the care of patients with oral anticoagulants.
                </Paragraph>
                <Button disabled style={{ marginTop: "8px" }}>
                  Coming soon!
                </Button>
              </div>
            </Col>

            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={6}
              style={{ padding: "0 12px" }}
            >
              <div
                style={{
                  backgroundColor: "#882941",
                  padding: "24px",
                  borderRadius: "6px",
                  boxShadow: "0 -2px 6px rgba(0, 0, 0, 0.2)",
                  marginBottom: "24px",
                }}
              >
                <Title style={{ color: "#fff", fontSize: "18px" }}>
                  het Acute Boekje <FlagIcon code="NL" size={16} />
                </Title>
                <Paragraph
                  style={{ fontSize: "85%", marginTop: "16px", color: "#fff" }}
                >
                  'het Acute Boekje' contains medical guidelines for the
                  diagnosis and treatment of disorders in the field of internal
                  medicine.
                </Paragraph>
                <Paragraph
                  style={{ fontSize: "85%", marginTop: "16px", color: "#fff" }}
                >
                  In collaboration with the Dutch Association for Internal
                  Medicine (NIV) we're developing multiple flowcharts containing
                  a diverse range of interactions and widgets.
                </Paragraph>
                <Button disabled style={{ marginTop: "8px" }}>
                  Coming soon!
                </Button>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default InTheWild;
